<template>
  <div class="document-white-board c-width">
    <div class="doc-control">
      <div class="ygb icon" @click="switchControls(0)">
        <i class="iconfont iconyingguangbi"></i>
      </div>
      <div class="ygb-box box" v-show="selectedTypes[0].flag">
        <div
          class="size"
          v-for="(item, index) in ygbSize"
          :key="index"
          @click="setYgbSize(item.size)"
        >
          <i :style="{ width: item.width, height: item.width }"></i>
        </div>
      </div>
      <div class="hb icon" @click="switchControls(1)">
        <i class="iconfont iconbi"></i>
      </div>
      <div class="hb-box box" v-show="selectedTypes[1].flag">
        <div
          class="size"
          v-for="(item, index) in hbSize"
          :key="index"
          @click="setHbSize(item.size)"
        >
          <i :style="{ width: item.width, height: item.width }"></i>
        </div>
      </div>
      <div class="color icon" @click="switchControls(2)">
        <i class="iconfont iconyanse"></i>
      </div>
      <div class="color-box box" v-show="selectedTypes[2].flag">
        <div
          class="size"
          v-for="(item, index) in colors"
          :key="index"
          @click="setColor(item.color)"
        >
          <i :style="{ backgroundColor: item.color }"></i>
        </div>
      </div>
      <div class="text icon" @click="setFont($event)">
        <i class="iconfont iconziti"></i>
      </div>
      <div class="xiangpi icon" @click="setEraser">
        <i class="iconfont iconxiangpi"></i>
      </div>
      <div class="del icon" @click="del">
        <i class="iconfont iconshanchu"></i>
      </div>
    </div>
    <div class="doc-content">
      <div class="header">
        <div class="circle"></div>
        <div class="circle"></div>
        <div class="circle"></div>
      </div>
      <div class="my-doc" id="my-doc"></div>
      <div class="footer">
        <div class="icon">
          <i class="iconfont icondouble-arrow-left" @click="toFirst"></i>
        </div>
        <div class="icon">
          <i class="iconfont iconarrow-lift" @click="goPrev"></i>
        </div>
        <div class="icon">
          <i class="iconfont iconarrow-right" @click="goNext"></i>
        </div>
        <div class="icon">
          <i class="iconfont icondouble-arro-right" @click="toFinal"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectedType: false,
      sdk: null,
      elId: '',
      totalPage: 0,
      selectedTypes: [
        {
          flag: false
        },
        {
          flag: false
        },
        {
          flag: false
        }
      ],
      ygbSize: [
        {
          width: '6px',
          size: 4
        },
        {
          width: '8px',
          size: 8
        },
        {
          width: '12px',
          size: 12
        },
        {
          width: '14px',
          size: 16
        },
        {
          width: '16px',
          size: 20
        }
      ],
      hbSize: [
        {
          width: '6px',
          size: 4
        },
        {
          width: '8px',
          size: 8
        },
        {
          width: '12px',
          size: 12
        },
        {
          width: '14px',
          size: 16
        },
        {
          width: '16px',
          size: 20
        }
      ],
      colors: [
        {
          color: '#FF0200'
        },
        {
          color: '#008002'
        },
        {
          color: '#0800FF'
        },
        {
          color: '#333333'
        }
      ]
    }
  },
  mounted() {
    this.initDoc()
  },
  methods: {
    setYgbSize(size) {
      this.sdk.setHighlighters()
      this.sdk.setStrokeWidth({ width: size })
    },
    setHbSize(size) {
      this.sdk.setPen()
      this.sdk.setStrokeWidth({ width: size })
    },
    setColor(color) {
      this.sdk.setStroke({ color: color })
    },
    setFont(event) {
      this.sdk.setText()
    },
    setEraser() {
      this.sdk.setEraser()
    },
    del() {
      this.sdk.clear()
    },
    toFirst() {
      this.sdk.gotoPage({ page: 0 })
    },
    toFinal() {
      this.sdk.gotoPage({ page: this.totalPage })
    },
    goPrev() {
      console.log(235423)
      this.sdk.prevPage()
    },
    goNext() {
      this.sdk.nextPage()
    },
    switchControls(idx) {
      this.selectedTypes.forEach((el, index) => {
        if (index !== idx) {
          el.flag = false
        }
      })
      this.selectedTypes[idx].flag = !this.selectedTypes[idx].flag
    },
    initDoc() {
      let opt = {}
      let docId = ''
      if (process.env.VUE_APP_MODE === 'production') {
        opt = {
          appId: 'd317f559',
          channelId: 'ch_b9dd840e', // ch_b9dd840e , ch_2a61d5bd
          role: 1,
          isVod: false,
          roomId: 'lss_706f5237', // lss_706f5237 , lss_e82c916c
          client: VHDocSDK.Client.PC_WEB,
          accountId: 'master_4382',
          token: 'access:d317f559:b3acfa862ae09232'
        }
        docId = '835f28d6'
      } else {
        opt = {
          appId: 'd317f559',
          channelId: 'ch_2a61d5bd',
          roomId: 'lss_e82c916c',
          role: 1,
          isVod: false,
          client: VHDocSDK.Client.PC_WEB,
          accountId: 'master_7280',
          token: 'access:d317f559:b3acfa862ae09232'
        }
        docId = '253dccce'
      }
      this.sdk = VHDocSDK.createInstance(
        opt,
        res => {
          this.id = this.sdk.createUUID('document')
          this.sdk.createDocument({
            id: this.id,
            docId, // 835f28d6 , 253dccce
            elId: 'my-doc',
            width: 1020,
            height: 574
          })
          this.sdk.selectContainer({ id: this.id })
          const opts = {
            docId, // 文档id  835f28d6 , 253dccce
            id: this.id // 容器id
          }
          this.sdk.loadDoc(opts)
          this.sdk.on(VHDocSDK.Event.DOCUMENT_LOAD_COMPLETE, res => {
            this.totalPage = res.info.slidesTotal
          })
        },
        e => {
          console.log(e)
        }
      )
    }
  }
}
</script>

<style lang="less" scoped>
@import '../../../assets/less/varible.less';
.document-white-board {
  display: flex;
  align-items: flex-start;
  margin: 50px auto;
  justify-content: center;
  .flex {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .doc-control {
    width: 50px;
    margin-top: 43px;
    box-shadow: @boxShadow;
    border-radius: 4px;
    overflow: hidden;
    .icon {
      .flex;
      height: 50px;
      background: #fff;
      box-sizing: border-box;
      border-bottom: 1px solid #eaecf3;
      cursor: pointer;
      &:hover {
        background: @mainColor;
        color: #fff;
      }
    }
    .iconfont {
      font-size: 20px;
    }
    .box {
      background: #e6e9ee;
      .size {
        width: 50px;
        height: 30px;
        cursor: pointer;
        &:hover {
          background: #fff;
        }
        .flex;
        i {
          display: inline-block;
          border-radius: 50%;
          background: #555;
        }
      }
      &.color-box {
        i {
          width: 30px;
          height: 18px;
          border-radius: 0;
        }
      }
    }
  }

  .doc-content {
    margin-left: 50px;
    width: 1020px;
    background: #fff;
    box-shadow: @boxShadow;
    .header {
      display: flex;
      align-items: center;
      height: 43px;
      .circle {
        width: 11px;
        height: 11px;
        background: #999;
        border-radius: 50%;
        margin-left: 10px;
      }
    }
    .footer {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 73px;
      .icon {
        width: 30px;
        height: 30px;
        box-shadow: @boxShadow;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        .iconfont {
          font-size: 20px;
          color: #999;
        }
        &:not(:first-child) {
          margin-left: 10px;
        }
        &:hover {
          background: @mainColor;
          .iconfont {
            color: #fff;
          }
        }
      }
    }
    .my-doc {
      position: relative !important;
    }
  }
}
</style>
