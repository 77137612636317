<template>
  <div class="docment-wrap gray-bcg-color">
    <c-banner :bannerData="bannerData"
              :src="src"></c-banner>
    <c-menu :menuData="menuData"></c-menu>
    <a name="advantage"
       class="anchor-replace"></a>
    <div class="v-advantage c-width">
      <c-title :titleArr="advantageTitle"></c-title>
      <function-desc :imgData="imgData"
                     :functionData="functionData"></function-desc>
    </div>
    <a name="function"
       class="anchor-replace"></a>
    <div>
      <c-title :titleArr="functionTitle"></c-title>
      <c-doc></c-doc>
    </div>
    <a name="solution"
       class="anchor-replace"></a>
    <div class="v-solution">
      <c-title :titleArr="solutionTitle"></c-title>
      <c-solution :src="solutionSrc"></c-solution>
    </div>
    <a name="price"
       class="anchor-replace"></a>
    <div>
      <c-title :titleArr="priceTitle"></c-title>
      <c-price :priceData="priceData">
        <div slot="content">
          <div class="add-price-item">多格式文档上传</div>
          <div class="add-price-item">丰富的演示效果</div>
          <div class="add-price-item">全套Demo、SDK、开发文档等</div>
        </div>
      </c-price>
    </div>
    <a name="doc"
       class="anchor-replace"></a>
    <div class="document">
      <c-title class="doc"
               :titleArr="documentTitle"
               :isDocTitle="true"></c-title>
      <div class="doc-content c-width">
        <a :href="`${docSrc}docs/show/560.html`"
           target="_blank">产品文档</a>
        <a :href="`${docSrc}docs/show/566.html`"
           target="_blank">快速开始</a>
        <a :href="`${docSrc}docs/show/569.html`"
           target="_blank">API</a>
        <a :href="`${docSrc}docs/show/626.html`"
           target="_blank">SDK</a>
        <a :href="`${docSrc}docs/show/629.html`"
           target="_blank">常见问题</a>
      </div>
    </div>
    <a name="recommand"
       class="anchor-replace"></a>
    <div>
      <c-title :titleArr="recommendTitle"></c-title>
      <c-recommend curService="doc"></c-recommend>
    </div>
  </div>
</template>

<script>
import { cRecommend, cBanner, cPrice, cMenu, cSolution } from '../components'
import cDoc from './doc'
import { cTitle } from '@/components'
import functionDesc from './components/function-desc'
export default {
  components: {
    cRecommend,
    cBanner,
    cPrice,
    cMenu,
    cSolution,
    cTitle,
    functionDesc,
    cDoc
  },
  data () {
    return {
      docSrc: window.urlConfig.docUrl,
      advantageTitle: ['功能介绍'],
      functionTitle: ['功能演示'],
      solutionTitle: ['端到端的解决方案'],
      priceTitle: ['产品价格'],
      documentTitle: ['产品文档'],
      recommendTitle: ['相关推荐'],
      imgData: [
        {
          className: 'v-img1',
          src: require('../../../assets/images/doc/advantage1_1@2x.png')
        },
        {
          className: 'v-img2',
          src: require('../../../assets/images/doc/advantage2_1@2x.png')
        },
        {
          className: 'v-img3',
          src: require('../../../assets/images/doc/advantage3_1@2x.png')
        },
        {
          className: 'v-img4',
          src: require('../../../assets/images/doc/advantage4_1@2x.png')
        }
      ],
      functionData: [
        {
          className: 'v-frame1',
          src: require('../../../assets/images/doc/advantage1@2x.png'),
          title: '文档上传',
          text: '多格式文档上传，并支持文档自动转化为jpeg格式图片及HTML页面格式'
        },
        {
          className: 'v-frame2',
          src: require('../../../assets/images/doc/advantage2@2x.png'),
          title: '文档演示',
          text:
            '通过微吼云提供的SDK，进行文档翻页、文档标注、白板互动等多种形式的文档演示'
        },
        {
          className: 'v-frame3',
          src: require('../../../assets/images/doc/advantage3@2x.png'),
          title: '文档录制',
          text: '支持视频、文档、聊天内容同步录制，并发布为点播视频'
        },
        {
          className: 'v-frame4',
          src: require('../../../assets/images/doc/advantage4@2x.png'),
          title: '文档观看',
          text: '支持iOS、Android、JS端文档观看SDK，观看文档演示效果'
        }
      ],
      isShowOneGroup: true,
      bannerData: {
        title: '文档白板',
        textData: [
          '微吼云文档服务',
          '支持多种格式文档上传',
          '并可在演示文档过程中进行书写',
          '标注和绘画等互动方式'
        ],
        href2: `${window.urlConfig.docUrl}docs/show/624.html`
      },
      src: require('../../../assets/images/doc/doc-banner.png'),
      solutionSrc: require('../../../assets/images/doc/solution@2x.png'),
      advantageData1: [
        {
          flag: true,
          src: require('../../../assets/images/advantage2@2x.png')
        },
        {
          flag: true,
          src: require('../../../assets/images/advantage2@2x.png')
        },
        {
          flag: false,
          src: require('../../../assets/images/advantage4@2x.png')
        },
        {
          flag: false,
          src: require('../../../assets/images/advantage4@2x.png')
        }
      ],
      priceData: [
        {
          bcg: '#00EB82',
          title: '使用文档白板功能',
          text: '1.0元／千页',
          adviceUrl: '',
          detailUrl: `${window.urlConfig.docUrl}docs/show/562.html`
        }
      ],
      menuData: [
        {
          src: require('../../../assets/images/subnav1@2x.png'),
          id: '#advantage',
          text: '功能介绍'
        },
        {
          src: require('../../../assets/images/subnav2@2x.png'),
          id: '#function',
          text: '功能演示'
        },
        {
          src: require('../../../assets/images/subnav3@2x.png'),
          id: '#solution',
          text: '解决方案'
        },
        {
          src: require('../../../assets/images/subnav4@2x.png'),
          id: '#price',
          text: '产品价格'
        },
        {
          src: require('../../../assets/images/subnav5@2x.png'),
          id: '#doc',
          text: '产品文档'
        },
        {
          src: require('../../../assets/images/subnav6@2x.png'),
          id: '#recommand',
          text: '相关推荐'
        }
      ],
      productFunData: [
        {
          className: 'interactPlat',
          title: '跨平台互动',
          desc:
            '支持Android、iOS、WEB主流平台开播、观看及互动；支持一对多，多对多的互动直播'
        },
        {
          className: 'interactLetter',
          title: '音视频通信',
          desc: '支持音视频通信，提供高质量的音视频通信效果'
        },
        {
          className: 'interactShare',
          title: '桌面分享',
          desc: '支持jssdk屏幕共享'
        },
        {
          className: 'interactRecord',
          title: '录制',
          desc:
            '支持混流视频的录制，将混流录制下来的视频存储在点播服务中，可在点播服务中对视频进行管理'
        },
        {
          className: 'interactLive',
          title: '旁路直播',
          desc: '支持直播混流，并将混流画面推送至指定的直播房间'
        },
        {
          className: 'interactjianhuang',
          title: '智能鉴黄',
          desc:
            '支持对涉黄行为进行高效自动识别，可根据用户用户管控尺度，灵活调整策略'
        },
        {
          className: 'interactPs',
          title: '美颜滤镜',
          desc: '提供移动端SDK美颜滤镜功能，支持移动sdk上的美白，磨皮'
        }
      ]
    }
  },
  mounted () {}
}
</script>

<style lang="less" scoped>
.document-wrap {
  .on {
    position: absolute;
    top: 0;
    opacity: 0;
  }
}
.doc {
  padding-top: 44px;
}
.doc-content {
  display: grid;
  grid-template-columns: repeat(5, 20%);
  margin-top: 53px;

  a {
    color: #bfcddd;
    font-size: 18px;
    text-align: center;
    line-height: 32px;
    font-weight: 500;

    &:not(:last-child) {
      border-right: 1px solid #fff;
    }

    &:hover {
      color: #fff;
    }
  }
}

.add-price-item {
  width: 598px;
  font-size: 20px;
  color: #555;
  font-weight: 500;

  &:first-child {
    margin-top: 30px;
  }
}

.v-advantage {
  position: relative;
}
</style>
